import React, { useState, useEffect} from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import '../../../css/userprofile.css';
import LineChart from '../../../charts/LineChart';

import { StudentService } from '../../../services/student-services';
import { CommonService } from '../../../services/common-services';
import Navbar from '../../common/Navbar';

const customStyles = {
  content: {
    margin: "0 auto",
    maxWidth:'800px',
    minHeight:'80vh',
    overflow: "scroll",
  },
};

Modal.setAppElement(document.getElementById('root'));

const StudentProfile = () => {

  // students profile data
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState({});
  const [userBlogsReadData, setUserBlogsReadData] = useState({});
  const [modelUserData, setModelUserData] = useState({});
  const [refresh, setRefresh] = useState(false); // State to trigger useEffect

  // edit profile data
  const [skills, setSkills] = useState([]);
  const [interests, setInterests] = useState([]);

  // data available to select from
  const interestsOptions = ['Software Development', 'E-commerce', 'HealtTech', 'FinTech', 'Gaming', 'Ed Tech', 'IOT'];
  const skillsOptions = ['JavaScript', 'Python', 'Java', 'C++', 'Ruby', 'Swift','Web Development', 'Data Science', 'Mobile App Development', 'UI/UX', 'Artificial Intelligence', 'Machine Learning', 'Cyber Security'];

  // options to select from
  const selectOptions = (array) => {
    return array.map(option => ({ value: option, label: option }));
  };

  const getUserData = async () => {
    const data = await StudentService.getStudentInfo()
    console.log("🚀 ~ getUserData ~ data:", data.userData);
    setUserData(data.userData);
    setModelUserData(data.userData);
  }

  const getUserBlogReadData = async () => {
    const data = await CommonService.getBlogsReadCumulativeCount();
    setUserBlogsReadData(data?.cumulativeCounts);
    console.log("🚀 ~ getUserBlogReadData ~ getUserBlogReadData:", userBlogsReadData);
  }

  // handle profile data changes
  const handleSkillsChange = (selectedOptions) => {
    setSkills(selectedOptions);
  };

  const handleInterestsChange = (selectedOptions) => {
    setInterests(selectedOptions);
  };

  let navigate = useNavigate();

  useEffect(() => {
    if(!localStorage.getItem('token')){
      navigate("/user-login")
    }
    
    // get user data
    getUserData();

    // get user blog read data
    getUserBlogReadData();
  // eslint-disable-next-line 
}, [refresh])

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setSkills(modelUserData?.skills?.map(skill => ({
      value: skill,
      label: skill,
    })));
    setInterests(modelUserData?.interests?.map(interest => ({
      value: interest,
      label: interest,
    })));
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.

  }

  function closeModal() {
    setModelUserData(userData);
    setIsOpen(false);
  }

  const saveChange = async() => {
    const res = await saveUserData();
    console.log("response", res);
    if (res.data?.status === 200) {
      // navigate("/student/profile");
      console.log("data updated");
    } else {
      alert("error in updating user credentials. Please mail to mandeep.b@viablelab.io")
    }
    console.log('Save');
    // Set refresh state to true after the update
    setRefresh(prev => !prev);  // Toggle the refresh state
    setIsOpen(false);
  }

  // Handle input change for multiple fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;  // Get the name and value of the input field
    setModelUserData({
      ...modelUserData,
      [name]: value  // Update the specific field in the state
    });
  };

// save user data
  const saveUserData = async () => {
    const skills_array = skills.map(item => item.value);
    const interests_array = interests.map(item => item.value);
    if (modelUserData?.firstname && modelUserData?.email) {
      const param = {
        email: modelUserData?.email,
        firstname: modelUserData?.firstname,
        lastname: modelUserData?.lastname,
        skills: skills_array,
        interests: interests_array,
        github: modelUserData?.github,
        linkedin: modelUserData?.linkedin,
        headline: modelUserData?.headline,
        achievement: modelUserData?.achievement,
      }
      const res = await StudentService.updateStudent(param);
      return res;
    } else {
      alert("error in updating user credentials. FirstName and email are mandatory parameter.");
    }
  }

  return (
    <>
    {/* <Navbar/> */}
    <div className='user-profile-page'>
      <div className='user-profile-content box-style1'>
        <div className='left-section'>
        <div className='photo-score'>
          <div className='photo-section'>
              {/* <div className='profile-image'>
                <img src={userData?.image} alt="Profile" />
              </div> */}
              <div className='d-flex gap-1 justify-content-between flex-wrap'>
                <h3>{userData?.firstname + " " + userData?.lastname}</h3>
                <div  className='button button-style2 fit-content' onClick={openModal}>Edit Profile</div>
              </div>
             
          </div>
        </div>
        <div>
        <div className='title-style2 font-size-small-title'>Blogs Read</div>
        {userBlogsReadData && <LineChart data={userBlogsReadData} />}
        {!userBlogsReadData && "Chart not available"} 
        </div>

        <div className='achievement-section box-style2'>
            <div className='title-style2 font-size-small-title'>Headline</div>
            <div className='achievements'>
              <p>{userData?.headline}</p>
            </div>
          </div>
          
          <div className='achievement-section box-style2'>
            <div className='title-style2 font-size-small-title'>Achievements</div>
            <div className='achievements'>
              {userData?.achievement}
            </div>
          </div>

          <div className='achievement-section box-style2'>
            <div className='title-style2 font-size-small-title'>Skills</div>
            <div className='skills-flex'>
              {userData?.skills?.map((item, index) => {
                return <div key={index} className='button-style-profile'>{item} </div>;
              })}
            </div>
          </div>

          <div className='achievement-section box-style2'>
            <div className='title-style2 font-size-small-title'>Interests</div>
            <div className='skills-flex'>
              {userData?.interests?.map((item, index) => {
                return <div key={index} className='button-style-profile'>{item} </div>;
              })}
            </div>
          </div>

        </div>

        <div className='right-section box-style4'>
          <div className= 'education-section'>
            <div className='title-style2 font-size-small-title text-center'>Personal Details</div>
            <div className = 'user-info-flex'>
              <div>
                <div>Email</div>  <div>{userData?.email}</div>
              </div>
              <div>
                <div>Website</div> <div><a href={userData?.website}>{userData?.website || "NA"}</a></div>
              </div>
              <div>
                <div>LinkedIn</div> <div><a href={userData?.linkedin}>{userData?.linkedin}</a></div>
              </div>
              <div>
                <div>Github</div> <div><a href={userData?.github}>{userData?.github}</a></div>
              </div>
            {/* <div>Phone</div> <div>{user.personal_details.phone}</div> */}
            </div>
          </div>
          {/* <div className='personal-detail-section'> */}
          {/* <div className='title-style2 font-size-small-title text-center'>Personal Details</div> */}
          {/* <div className = 'user-info-grid'> */}
            
          </div>             
        {/* </div> */}
        </div>

      </div>

      <div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Info Modal"
      >
        <div className='title-style2 font-size-small-title text-center'>Update Information</div>
        <form>
          <div className='edit-info-modal'>
            <div className='left-side'>
            {/* <div className='photo-section'>
              <div className='profile-image'>
                <img src={user.image} alt="Profile" />
              </div>
                <div className='button-style1 git-content'>Change Photo</div>
            </div> */}

              <div>
                <div>First Name</div>
                <div>
                  <input
                    type='text'
                    name='firstname'
                    value={modelUserData?.firstname}
                    onChange={handleInputChange}
                  />
                  </div>
              </div>
              <div>
                <div>Last Name</div>
                <div>
                  <input
                    type='text'
                    name='lastname'
                    value={modelUserData?.lastname}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div>
                <div>Headline</div>
                <div>
                  <textarea
                    name='headline'
                    value={modelUserData?.headline}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div>
                <div>Achievements</div>
                <div>
                  <textarea
                    name='achievement'
                    value={modelUserData?.achievement}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className='right-side'>
            <div>
                <div>Skills</div>
                  <div className='multi-select'>
                    <Select
                    isMulti
                    name="skills"
                    options={selectOptions(skillsOptions)}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleSkillsChange}
                    value={skills}
                    />
                </div>
              </div>
              <div>
                <div>Interests</div>
                <div className='multi-select'>
                  <Select
                  isMulti
                  name="interest"
                  options={selectOptions(interestsOptions)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleInterestsChange}
                  value={interests}
                />
              </div>
              </div>
              <div>
                <div>Email</div>
                <div>
                  <input
                    type='email'
                    name='email'
                    value={modelUserData?.email}
                  />
                </div>
              </div>
              <div>
                <div>Website</div>
                <div>
                  <input
                     type='text'
                     name='website'
                     value={modelUserData?.website}
                     onChange={handleInputChange}
                  />
                </div>
              </div>
              <div>
                <div>LinkedIn</div>
                <div>
                  <input
                    type='text'
                    name='linkedin'
                    value={modelUserData?.linkedin}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div>
                <div>Github</div>
                <div>
                  <input
                    type='text'
                    name='github'
                    value={modelUserData?.github}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {/* <div>
                <div>Github</div>
                <div><input type='text' value={user.links.github}></input></div>
              </div>
              <div>
                <div>Website</div>
                <div><input type='text' value={user.links.website}></input></div>
              </div> */}
              <div className='modal-button-group'>
                <div className='btn button-style2 fit-content' onClick={saveChange}>Save</div>
                <div className='btn button-style1 fit-content' onClick={closeModal}>Close</div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
    </>
  );
};

export default StudentProfile;
