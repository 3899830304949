import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css'
import CompanyHomepage from "./pages/company/services/Homepage";
import CompanyDashboard from "./pages/company/services/Dashboard";
import CompanySignup from "./pages/company/user/Signup";
import CompanyCreateProfile from "./pages/company/user/CreateProfile";
import CompanyLogin from "./pages/company/user/Login";
import CompanyJobList from "./pages/company/services/JobList";
import CompanyPostInternship from "./pages/company/services/PostInternship";
import StudentHomepage from "./pages/student/services/Homepage";
import StudentSignup from "./pages/student/user/Signup";
import StudentCreateProfile from "./pages/student/user/CreateProfile";

import StudentProfile from "./pages/student/user/Profile";
import StudentLogin from "./pages/student/user/Login";
import StudentRegistrationForm from "./pages/student/services/RegistrationForm";
import StudentFileUpload from "./pages/student/services/FileUpload";
import Error from "./pages/common/Error";
import Navbar from "./pages/common/Navbar";
import Landing from "./pages/common/Landing";
import ContactUs from "./pages/common/ContactUs";
import Courses from "./pages/student/services/Courses";
import Blogs from "./pages/student/services/Blogs";
import ProjectDashboard from "./pages/student/services/ProjectDashboard";
import { AuthProvider } from "./pages/common/AuthContext";
import PrivateRoute from "./pages/common/PrivateRoute";
import ForgotPassword from "./pages/common/ForgotPassword";
import StudentJobDashboard from "./pages/student/services/JobDashboard/JobDashboard";
import Notes from "./pages/student/services/Notes";

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <Navbar/>
          <div className="app-content container">
          <Routes>
            {/* COMMON */}
            <Route exact path="/" element={<Landing/>} />
            <Route exact path="/contact" element={<ContactUs/>}/>   
            <Route exact path="/student/login" element={<StudentLogin/>}/>   
            <Route exact path="/student/signup" element={<StudentSignup/>}/> 
            {/* <Route exact path="/company/login" element={<CompanyLogin />}/> */}
            {/* <Route exact path="/company/signup" element={<CompanySignup/>}/> */}
            <Route exact path="/forgot-password" element={<ForgotPassword/>}/>
            <Route path="/student/create-profile"  element={<StudentCreateProfile/>}/>
            {/* <Route path="/company/create-profile" element={<CompanyCreateProfile />}/> */}
            {/* Company */}
            {/* <Route path="/company/homepage" element={<PrivateRoute><CompanyHomepage /></PrivateRoute>} />
            <Route path="/company/dashboard" element={<PrivateRoute><CompanyDashboard /></PrivateRoute>} />
            <Route path="/company/signup" element={<PrivateRoute><CompanySignup /></PrivateRoute>} /> */}
            
            <Route path="/student/job-list" element={<PrivateRoute><CompanyJobList /></PrivateRoute>} />
            {/* <Route path="/company/post/internship" element={<PrivateRoute><CompanyPostInternship /></PrivateRoute>} /> */}
            {/* student */}
            <Route path="/student/homepage" element={<PrivateRoute><StudentHomepage /></PrivateRoute>}/>
            <Route path="/student/job-dashboard" element={<PrivateRoute><StudentJobDashboard /></PrivateRoute>} />
            <Route path="/student/profile" element={<PrivateRoute><StudentProfile /></PrivateRoute>} />
            <Route path="/student/registration" element={<PrivateRoute><StudentRegistrationForm /></PrivateRoute>} />
            <Route path="/file/upload" element={<PrivateRoute><StudentFileUpload /></PrivateRoute>} />
            <Route path="/student/blogs" element={<PrivateRoute><Blogs /></PrivateRoute>} />
            <Route path="/student/notes" element={<PrivateRoute><Notes /></PrivateRoute>} />
            <Route path="/student/project-dashboard" element={<PrivateRoute><ProjectDashboard /></PrivateRoute>} />
            <Route path="/student/courses" element={<PrivateRoute><Courses /></PrivateRoute>} />
            {/* NOT FOUND */}
            <Route  path="*" element={<Error />} />
          </Routes>
          </div>
          
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
