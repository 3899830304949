import axios from "axios";

// get backend endpoint
const backend_endpoint = process.env.REACT_APP_BACKEND_ENDPOINT;

const checkEmail = async (param) => {
    try {
      const response = await axios.post(`${backend_endpoint}/misc/check-email`, param, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response
    } catch (error) {
      console.error('Error:', error.message);    
    }
}

const sendOTP = async (param) => {
  try {
    const response = await axios.post(`${backend_endpoint}/otp/forgot-password-otp`, param, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response
  } catch (error) {
    console.error('Error:', error.message);    
  }
}

const sendSignupOTP = async (param) => {
  try {
    const response = await axios.post(`${backend_endpoint}/otp/send-otp`, param, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response
  } catch (error) {
    console.error('Error:', error.message);    
  }
}

const verifyOTP = async (param) => {
  try {
    const response = await axios.post(`${backend_endpoint}/otp/verify-otp`, param, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data
  } catch (error) {
    console.error('Error:', error.message);    
  }
}

const changePassword = async (param) => {
  try {
    const response = await axios.post(`${backend_endpoint}/otp/change-password`, param, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response
  } catch (error) {
    console.error('Error:', error.message);    
  }
}

const getAllBlogs = async (param) => {
  try {
    const response = await axios.get(`${backend_endpoint}/blog/getallblogs`, {
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
        'x-Params-Page-Limit': param.page_limit,
        'x-Params-Page-Number': param.page_number,
        'x-Params-Labels': param.labels
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const getAllBlogsLabel = async () => {
  try {
    const response = await axios.get(`${backend_endpoint}/blog/getAllBlogsLabel`, {
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
    } );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

// ai search blogs
const getAllSearchBlogs = async (param) => {
  try {
    const response = await axios.get(`${backend_endpoint}/blog/getallsearchblogs`, {
      headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
        'x-Params-Search-Query': param.search_query,
        'x-Params-Page-Limit': param.page_limit,
        'x-Params-Page-Number': param.page_number
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

// send call for user opened blog
const openedBlog = async (url, date) => {
  try {
    const response = await axios.post(`${backend_endpoint}/blog/opened`,{url,date},{
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      }
    } );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const getBlogsReadCumulativeCount = async () => {
  try {
    const response = await axios.get(`${backend_endpoint}/blog/getBlogsReadCumulativeCount`)
    return response.data;
  }
  catch (error) {
    console.log("Error:", error.message);
  }
}

const getAllNotes = async () => {
  try {
    const response = await axios.get(`${backend_endpoint}/notes/getAllNotes`, {
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
    } );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const getAllTags = async () => {
  try {
    const response = await axios.get(`${backend_endpoint}/student/getTags`, {
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
    } );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const addNoteTag = async (note_id,new_tag) => {
  try {
    const response = await axios.post(`${backend_endpoint}/notes/addTag`,{note_id,new_tag},{
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      }
    } );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const editNote = async (url,content) => {
  try {
    const response = await axios.post(`${backend_endpoint}/notes/save`,{url,content},{
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      }
    } );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const removeNoteTag = async (note_id,tag_to_remove) => {
  try {
    const response = await axios.delete(`${backend_endpoint}/notes/removeTag`, {
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
      data: {
        note_id,
        tag_to_remove
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const createUserTag = async (new_tag) => {
  try {
    const response = await axios.post(`${backend_endpoint}/student/createTag`, {new_tag}, {
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      }
    } );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const createNote = async (newNote) => {
  try {
    const response = await axios.post(`${backend_endpoint}/notes/save`, {content : newNote.content , url: newNote.url}, {
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      }
    } );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const removeUserTag = async (tag_to_remove) => {
  try {
    const response = await axios.delete(`${backend_endpoint}/student/removeTag`, {
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
      data : {
        tag_to_remove
      }
    } );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}

const removeNote = async (note_id) => {
  try {
    const response = await axios.delete(`${backend_endpoint}/notes/removeNote`, {
       headers: {
        'Content-Type': 'application/json',
        'auth-token': localStorage.getItem('token'),
      },
      data : {
        note_id
      }
    } );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
  }
}


export const CommonService = {
  checkEmail,
  sendOTP,
  sendSignupOTP,
  verifyOTP,
  changePassword,
  getAllBlogs,
  getAllBlogsLabel,
  getAllSearchBlogs,
  openedBlog,
  getBlogsReadCumulativeCount,
  getAllNotes,
  removeNoteTag,
  addNoteTag,
  createUserTag,
  removeUserTag,
  editNote,
  getAllTags,
  createNote,
  removeNote
};