// LineChart.js
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const LineChart = ({ data = {} }) => {  // Default prop to prevent errors
  const labels = Object.keys(data);
  const counts = Object.values(data);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Blogs Read',
        data: counts,
        fill: false,
        borderColor: '#604a8b',
        tension: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Cumulative Count',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ aspectRatio: "16/9", width: '100%', maxWidth: '600px' }}> {/* Control chart size here */}
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LineChart;
